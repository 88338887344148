import { createSelector } from 'reselect';

const getUserState = (state) => state.user;

export const getUser = createSelector(getUserState, (userState) => userState.user || {});

export const getUserPrivs = createSelector(getUserState, (userState) => userState.user?.privileges || {});

export const getUserType = createSelector(getUserState, (userState) => userState.user?.type || '');

// a selector that accepts an id and returns the engagement privileges associated with that id
// const engagementLevelPrivs = useSelector((state) => getEngagementPermissions(state, engagementId));
export const getEngagementPermissions = createSelector(
    [getUserState, (_, id) => id],
    (userState, id) =>
        userState?.user.engagements?.find((engagement) => engagement.id === parseInt(id, 10))?.engagementPrivileges ||
        null,
);

export const getEngagementRoles = createSelector(
    [getUserState, (_, id) => id],
    (userState, id) =>
        userState?.user.engagements?.find((engagement) => engagement.id === parseInt(id, 10))?.roles || null,
);

export const getCookiesAnswered = createSelector(getUserState, (userState) => userState.user.cookies);
export const getTOSAccepted = createSelector(getUserState, (userState) => userState.user.tos);
