import { createTheme } from '@mui/material/styles';
import Colors from './colors';

const baseTheme = createTheme({
    padding: 5,
    spacing: 5,
    typography: {
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        fontSize: 16,
        h1: {
            fontFamily: "'Catamaran', san-serif",
            fontSize: '4.8rem',
            fontWeight: 600,
            letterSpacing: '-0.05rem',
        },
        h2: {
            fontFamily: "'Catamaran', san-serif",
            fontSize: '4.0rem',
            lineHeight: '4.2rem',
            fontWeight: 600,
        },
        h3: {
            fontFamily: "'Catamaran', san-serif",
            fontSize: '3.5rem',
            fontWeight: 300,
        },
        h4: {
            fontFamily: "'Catamaran', san-serif",
            fontSize: '2.6rem',
            fontWeight: 600,
        },
        h5: {
            fontFamily: "'Catamaran', san-serif",
            fontSize: '2.0rem',
            color: Colors.greyDark,
            fontWeight: 600,
        },
        h6: {
            fontFamily: "'Catamaran', san-serif",
            fontWeight: 800,
            fontSize: '1.4rem',
            color: Colors.blueMed,
            textTransform: 'uppercase',
        },
        body1: {
            fontSize: '1.4rem',
            lineHeight: '20px',
        },
        body2: {
            fontSize: '1.7rem',
        },
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        text: {
            primary: Colors.black,
            secondary: Colors.greyDark,
        },
        primary: {
            main: Colors.blueDark,
            light: Colors.blueMed,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.blueLight,
            // light: Colors.midnightLight,
            contrastText: Colors.white,
        },
        background: {
            default: Colors.blueLightest,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        body2: {
            color: baseTheme.palette.text.secondary,
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: {
            // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 'inherit',
                },
                root: {
                    '&.Checkbox-label, .Checkbox-label-checked': {
                        color: Colors.greyDark,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    position: 'relative',
                    top: 0,
                    left: 0,
                },
                asterisk: {
                    color: Colors.error,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                    fontWeight: 700,
                    color: Colors.greyDark,
                },
                asterisk: {
                    color: Colors.error,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                    lineHeight: '1.5rem',
                    marginLeft: 0,
                    marginRight: 0,
                    '&[role=alert]': {
                        color: Colors.error,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    // color: 'green',
                    fontSize: '1.6rem',
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${Colors.blueMed} !important`, // overrides the "disabled" border color
                    },
                    '&.Mui-disabled': {
                        '& svg.MuiSvgIcon-root': {
                            color: `${Colors.blueMed} !important`,
                            fill: `${Colors.blueMed} !important`,
                        },
                    },
                },
                select: {
                    // color: 'yellow',
                    '&.Mui-disabled': {
                        backgroundColor: Colors.greyLight,
                        WebkitTextFillColor: Colors.greyMed,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.dark,
                    '.MuiAlert-action': {},
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1.6rem',
                    color: Colors.greyDark,
                    marginTop: 5,
                    borderRadius: 5,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${Colors.blueMed}`,
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${Colors.blueDark}`,
                        },
                    },
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {},
                    },
                    '&.MuiOutlinedInput-error, &.MuiOutlinedInput-error.MuiOutlinedInput-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.error,
                        },
                    },
                    '&.MuiOutlinedInput-disabled': {
                        color: Colors.blueMed,
                        backgroundColor: Colors.blueLightest,
                        '& svg': {
                            // color: Colors.gray,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.blueMed,
                        },
                    },
                },
                input: {
                    padding: '14px 15px',
                    '&::placeholder': {
                        color: `rbga(${Colors.darkBlackRGB}, 0.7)`,
                        fontWeight: 400,
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '1.4rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.main,
                    lineHeight: '20px',
                    fontSize: '1.4rem',

                    '&:hover': {
                        backgroundColor: Colors.blueLightest,
                    },
                    '&[aria-selected=true]': {
                        color: Colors.greyDark,
                        fontWeight: 700,
                        backgroundColor: Colors.blueLight,
                    },
                    '&:focus': {
                        border: `1px solid ${Colors.blueMed}`,
                    },

                    '&[role=option]': {
                        // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    border: `1px solid ${Colors.blueMed}`,
                    borderRadius: 10,
                },
                grouped: {
                    '&:not(:first-of-type)': {
                        borderLeft: '8px solid transparent',
                        marginLeft: 0,
                    },
                    '&:not(:first-of-type).Mui-selected': {},
                    '&:first-of-type.Mui-selected': {
                        paddingLeft: 4,
                    },
                    '&:last-of-type': {
                        borderRight: 'none',
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    flexDirection: 'column',
                    backgroundColor: Colors.blueLightest,
                    color: Colors.blueLogo,
                    fontWeight: 800,
                    height: 115,
                    fontSize: '1.4rem',
                    borderRadius: 8, // this needs to match the border width applied on selection, not the border width of the parent
                    borderTop: 'none',
                    borderBottom: 'none',
                    '&:hover': {
                        backgroundColor: Colors.blueMed,
                    },
                    '&:focus': {
                        backgroundColor: Colors.blueLight,
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'white',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    lineHeight: '1.4rem',
                    textTransform: 'none',
                    fontSize: '1.6rem',
                    fontWeight: 700,
                    padding: '15px 30px',
                    '&:focus': {
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: Colors.blueMed,
                    },
                    '&:focus': {
                        backgroundColor: Colors.blueLogo,
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                containedSecondary: {
                    color: baseTheme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: Colors.blueMed,
                    },
                    '&:focus': {
                        backgroundColor: Colors.blueLight,
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                text: {
                    '&.side-nav': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        color: Colors.greyDark,
                        fontSize: '1.6rem',
                        fontWeight: 400,
                        borderRadius: '0px 5px 5px 0px',
                        height: 50,
                        '&:hover,&.active': {
                            color: 'white',
                            backgroundColor: Colors.blueDark,
                            boxShadow: '1px 1px 5px 1px rgba(212, 223, 233, 0.5)',
                        },
                    },
                },
                textSecondary: {
                    // header primary nav
                    backgroundColor: 'inheirt !important',
                    borderRadius: 0,
                    height: 50,
                    paddingLeft: 15,
                    paddingRight: 15,
                    color: '#9e9e9e',
                    fontSize: '1.6rem',
                    fontWeight: 500,
                    flexDirection: 'column',
                    // paddingBottom: 8,
                    ' .active-accent': {
                        display: 'none',
                        paddingTop: 5,
                        marginBottom: -5,
                    },
                    '&:hover': {
                        /*
                        paddingBottom: 2,
                        paddingTop: 5,
                        ' .active-accent': {
                            display: 'flex',
                        },
                        textDecoration: 'none',
                        */
                        backgroundColor: Colors.white,
                        color: Colors.blueMed,
                    },
                    '&.active': {
                        paddingBottom: 2,
                        paddingTop: 5,
                        ' .active-accent': {
                            display: 'flex',
                        },
                        color: Colors.greyDark,
                        fontWeight: 700,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontSize: '1.5rem',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    width: 'fit-content',
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    color: Colors.greyDark,
                    borderBottom: `3px solid ${Colors.blueLight}`,
                    '&.Mui-selected': {
                        borderBottom: `3px solid ${Colors.blueMed}`,
                        fontWeight: 600,
                        color: Colors.greyDark,
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    minWidth: '60%',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                filledError: {
                    backgroundColor: Colors.errorYellow,
                },
                filledSuccess: {
                    color: 'white',
                    backgroundColor: Colors.blueLogo,
                },
                root: {
                    width: '100%',
                    backgroundColor: Colors.blueLight,
                    color: Colors.greyDark,
                    fontSize: '1.6rem',
                    borderRadius: 5,
                    marginBottom: 15,
                    alignItems: 'center',
                    fontWeight: 400,
                    '& .MuiButton-root': {
                        fontSize: '1.6rem',
                        fontWeight: 700,
                        color: Colors.blueLogo,
                    },
                    '& .MuiAlert-action': {
                        paddingTop: '0px !important',
                        alignItems: 'center !important',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation2: {
                    // Tables
                    boxShadow: '1px 1px 5px 1px rgba(212, 223, 233, 0.5)',
                    borderRadius: '10px 0px 0px 0px',
                    width: '100%',
                    padding: 0,
                    paddingTop: 30,
                    paddingBottom: 30,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                footer: {
                    // no data
                    fontSize: '1.2rem',
                    color: Colors.midGray,
                    fontWeight: 700,
                    textAlign: 'center',
                    paddingTop: 30,
                    paddingBottom: 30,
                },
                root: {
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontSize: '1.6rem',
                    borderColor: Colors.blueLight,
                    color: Colors.greyDark,
                },
                head: {
                    fontSize: '1.4rem',
                    color: Colors.greyMed,
                    fontWeight: 800,
                    textTransform: 'uppercase',
                    paddingRight: 0,
                    '& .Mui-active': {
                        color: Colors.greyMed,
                    },
                },
                stickyHeader: {
                    height: 46,
                    backgroundColor: 'inherit',
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: `${Colors.greyMed} !important`,
                    },
                    '& .MuiTableSortLabel-icon': {
                        color: Colors.grey,
                        fontSize: 15,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: Colors.orange,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    '&.Mui-checked': {
                        color: Colors.orange,
                    },
                    '&.Mui-disabled': {
                        color: Colors.greyMed,
                    },
                    '&.Mui-checked.Mui-disabled': {
                        color: Colors.greyLight,
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${Colors.blueLight}`,
                    fontSize: '1.6rem',
                    color: Colors.greyDark,
                    '&:not(:last-child)': {},
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                    '&:last-of-type, :first-of-type': {
                        borderRadius: 0,
                    },
                    '&:before': {
                        display: 'none',
                    },
                    '& .MuiCollapse-root': {
                        paddingLeft: 10,
                    },
                    '& .MuiCollapse-root.MuiCollapse-entered': {
                        paddingTop: 15,
                        paddingBottom: 15,
                        backgroundColor: Colors.blueLightest,
                        color: Colors.blueLogo,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    paddingLeft: 20,
                    '&[data-error=true]': {
                        color: Colors.error,
                    },
                    '&.Mui-expanded': {
                        '&[data-error=true]': {
                            color: Colors.white,
                        },
                        backgroundColor: Colors.blueLogo,
                        color: Colors.white,
                        '& svg': {
                            color: Colors.white,
                        },
                    },
                },
                content: {
                    margin: '25px 0px',
                    '&.Mui-expanded': {
                        margin: '25px 0px',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    color: Colors.greyDark,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 78,
                },
                switchBase: {
                    '&.Mui-checked': {
                        transform: 'translateX(34px)',
                        '& + .MuiSwitch-track': {
                            backgroundColor: Colors.blueLight,
                        },
                        '& .MuiSwitch-thumb': {
                            backgroundColor: Colors.blueMed,
                        },
                    },
                },
                track: {
                    backgroundColor: Colors.greyLight,
                    height: 18,
                    borderRadius: 10,
                },
                thumb: {
                    backgroundColor: '#ccd4dc',
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                },
            },
        },
    },
});

export default theme;
